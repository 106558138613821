
import * as ipxRuntime$bLXOQnc2jF from '/app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 576,
    "sm": 768,
    "md": 996,
    "lg": 1200,
    "xl": 1367,
    "xxl": 1600,
    "2xl": 1536,
    "default": 320,
    "xxs": 480,
    "4k": 1921
  },
  "presets": {
    "card": {
      "modifiers": {
        "fit": "cover",
        "format": "avif",
        "quality": 80,
        "sizes": "sm:100vw md:50vw lg:400px"
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "storage.crossarea.org",
    "localhost",
    "crossarea.org",
    "localhost:3000",
    "img.youtube.com",
    "i.vimeocdn.com",
    "i.ytimg.com",
    "soundcloud.com",
    "unsplash.com",
    "fgzrisc.hypotheses.org",
    "recentglobe.hypotheses.org",
    "recentglobe.uni-leipzig.de",
    "www.uni-leipzig.de",
    "upload.wikimedia.org"
  ],
  "alias": {
    "/crossarea": "storage.crossarea.org",
    "/youtube": "https://img.youtube.com",
    "/vimeo": "https://i.vimeocdn.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$bLXOQnc2jF, defaults: {"maxAge":31536000} }
}
        